.pdf-container {
  background-color: #fff;
  margin-bottom: 10px;
}
.pdf-metadata {
  padding: 20px;
}

.pdf-header {
  text-align: center;
  background-color: #000000;
  color: #fff;
  padding: 5px;
}

.pdf-label {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}


.pdf-topic-header {
  background-color: #f9f9f9;
  font-size: 1.17em;
  margin: 0 -20px 10px -20px;
  padding: 8px 20px 8px 20px;
  font-weight: bold;
}

.pdf-h3 {
  font-size: 1.17em;
}
