.help-popover {
  margin-bottom: 15px;
  .bp3-popover2-target {
    display: inline!important;
    cursor: help;
    //margin-left: 10px;
    //margin-top: 5px;
    //text-transform: none;
  }
}

.help-icon {
  display: inline!important;
  //margin-left: 10px;
  cursor: help;
  font-size: 14px!important;
}
