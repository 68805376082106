.file-drop-image {
  border: 1px dashed #000;
  padding: 25px;
  width: 96px;
  height: 96px;
  display: inline-block;
  cursor: pointer;
  .file-add-image {
    position: absolute;
  }

  //cursor: pointer;
  &:hover {
    background-color: #EBECF0;
  }
  //height: 100px;
  &.dragging {
    background-color: #efefef;
  }
}
.image-preview-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  img {
    opacity: 1
  }
  .remove-image-button {
    position: absolute;
    display: none;
    width: 16px;
    height: 16px;
    top: 5px;
    right: 15px;
    //padding: 0;
    padding-left:3px;
    padding-bottom: 2px;
    background-color: #efefef;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: #e8e8e8
    }
  }
  &:hover {
    img {
      opacity: 0.5
    }
    .remove-image-button {
      display: inherit;
    }
  }
}
