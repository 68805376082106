.rfi-detail-section {
  //ackground-color: #bfbfbf;
  //margin: 0 -20px;
  //color: #142543
}

.rfi-form {
  .bp3-form-content {
    width: 100%;
  }
  .ql-editor {
    min-height: 300px;
  }
  .bp3-card {
    margin-bottom: 15px;
    h3 {
      margin-top: 0;
    }
  }
}
