.rfi-job-table-container {
  width: 100%;
  height: calc(100vh - 140px);
  background-color: #fff;
}

.rfi-job-details-container {
  width: 100%;
  height: calc(100vh - 195px);
  background-color: #fff;
}

.selected-job-row {
  background-color: rgba(25, 118, 210, 0.08);
}
