.meeting-table-container {
  width: 100%;
  height: calc(100vh - 200px);
  background-color: #fff;
}

.selectable-meeting-table-container {
  width: 100%;
  height: 350px;
  background-color: #fff;
}
