@import 'convenience.scss';
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import '~react-quill/dist/quill.snow.css';
@import "~@blueprintjs/table/lib/css/table.css";
@import "~rc-tree/assets/index.css";
@import "~ka-table/style.css";
@import 'handsontable/dist/handsontable.full.css';

.width-300 {
  width: 300px !important;
}
.width-500 {
  width: 500px !important;
}
.bg-white {
  background-color: #fff;
}
#hot-display-license-info {
  display: none;
}
.row-hover {
  &:hover {
    background-color: #ebecf0;
  }
}
.selected-row {
  background-color: #f0f7ff;
}
.parent {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 50px);
}

.parent > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 10ch;
}

.text-black {
  color: #2c2c2c;
}
.sidebar {
  flex-basis: 15rem;
  flex-grow: 1;
  //align-items: stretch;
  nav {
    background-color: #fff;
  }
}

.not-sidebar {
  flex-basis: 0;
  flex-grow: 100;
  padding: 0 20px;
  overflow-y: scroll;
  //background-color: #f5f8fa;
  background-color: #ebecf0;
  &.isDocuments {
    background-color: #fff;
    overflow: hidden;
    padding: 0;
  }
}

.text-left {
  text-align: left
}
.text-right {
  text-align: right!important;
}
.text-danger {
  color: #db3737;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}
.bp3-button:focus, .bp3-menu-item:focus, bp3-tab:focus, .bp3-control-indicator:focus {
  outline: none!important;
}
.bp3-toast-container.bp3-toast-container-inline {
  position: fixed;
}

.bp3-input:focus {
  //box-shadow: inherit;
  box-shadow: 0 0 0 0 #137CBD, 0 0 0 0 #137CBD, inset 0 0 0 1px #a4a3a3, inset 0 1px 1px #137CBD!important;
}
.bp3-tab-indicator-wrapper ~ .bp3-tab:focus,  {
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none!important;
}

.bp3-input:disabled, .bp3-input.bp3-disabled {
  background: rgba(206, 217, 224, 0.5);
  box-shadow: none;
  color: rgb(30, 34, 41);
  cursor: not-allowed;
  resize: none;
}

.bp3-control.bp3-disabled {
  color:#1e2229;
}

.bp3-control input:disabled:checked ~ .bp3-control-indicator {
  background: rgba(19, 124, 189, 1);
  box-shadow: none;
}

.cell-width {
  width: 20px;
}
.pull-right {
  float: right;
}
.medium-button {
  height: 36px;
  margin-top: 1px;
}

.bp3-toast-container.bp3-toast-container-inline {
  margin-left: 240px;
}

.react-select__menu,.react-select__menu-list {
  z-index: 9999!important;
}

.single-small {
  .react-select__indicators {
    max-height: 30px;
    min-height: 30px;
  }
  .react-select__control {
    max-height: 30px;
    min-height: 30px;
    .react-select__value-container {
      //margin-top: -7px;
    }
  }
}

.react-select__control--is-disabled {
  background-color: #e7ecf0!important;
  .react-select__single-value--is-disabled {
    color: #1e2229!important;
  }
  .react-select__indicators {
    display: none;
  }

}


.quill-disabled {
  .ql-toolbar {
    display: none;
  }
  .ql-disabled {
    background-color: #e7ecf0;
    border-top: 1px solid #ccc!important;
  }
}
.p-relative {
  position: relative;
}

.workflow-details {
  position: absolute;
  right: 10px;
  top: 10px;
}

.light-bg {
  background-color: #f6f7f9;
}

.bp4-editable-text-input {
  width:100%!important;
  //box-shadow: inset 0 -1px 0 rgb(17 20 24 / 15%), inset -3px 0 0 rgb(17 20 24 / 15%);
  border: none;
  //height: 20px; line-height: 20px; width: 60px;
}
.bp4-table-editable-text {
  padding: 0;
}

.bp4-editable-text-content {
  display: none;
}
.no-border {
  border: none;
}

.react-select__multi-value--is-disabled {
  .react-select__multi-value__label {
    padding-right: 5px;
  }
  .react-select__multi-value__remove {
    display: none!important;
  }
}
