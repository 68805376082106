.team-member-table {
  border-bottom: 1px solid #a4a3a3;
  border-top: 1px solid #a4a3a3;
}
.no-items-container {
  background-color: #fff;
  padding: 20px;
}


.team-member-row {
  background-color: #fff;
  padding: 10px;
  &.team-member-header {
    border-bottom: 1px solid #a4a3a3;
  }
  p {
    margin: 0;
    margin-top: 3px;
  }
  .bp3-tag {
    margin-top: 3px;
  }
}
