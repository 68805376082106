.basic-block {
  .MuiTreeItem-content {
    background-color: #fff !important;
  }
}

.basic-block-root {
  border-bottom: 1px solid #e8e8e8;
  //border-top: 1px solid #e8e8e8;
}
.total-block {
  .MuiTreeItem-content {
    cursor: default;
    background-color: #f8f8f8 !important;
  }

}
.ctc-job-table-container {
  width: 100%;
  height: calc(100vh - 180px);
  background-color: #fff;
}

.yellow-box {
  background-color: yellow;
  padding: 3px 0 3px 3px;
}
.bp4-table-container {
  .bp4-table-column-name {
    text-align: center;
  }
}
.bp4-table-container.bp4-table-no-rows.header-only {
  min-height: 0;
}
.table-key {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.sticky-header-group {
  th {
    top: 36px !important;
  }
}

.top-dialog {
  .MuiDialog-container {
    align-items: start!important;
  }
}
