.mentions {
  margin: 1em 0;
  margin-bottom: 0;


}

.comment-scroll-container {
  max-height: 300px;
  overflow-x: auto;
}
.mentions--singleLine .mentions__control {
  display: inline-block;
}
.mentions--singleLine .mentions__higlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 5px;
  border: 2px inset;
  border-radius: 5px;
}
.mentions--multiLine {
  margin-top: 0;
}

.mentions--multiLine .mentions__control {
  //font-family: monospace;
  font-size: 16px;
  line-height: 30px;
  //border: 1px solid silver;
  border-radius: 5px;
}
.mentions--multiLine .mentions__highlighter {
  //padding: 9px;
}
.mentions--multiLine .mentions__input {
  //padding: 12px;
  min-height: 63px;
  outline: 0;
  border: 0;
  line-height: 30px;
  //font-size: 16pt;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  z-index: 9999;
}

//.mentions__suggestions__item {
//  padding: 5px 15px;
//  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
//}

//.mentions__suggestions__item--focused {
//  background-color: #cee4e5;
//}

.mentions__mention {
  background-color: #cee4e5;
  //height: 100%;
  //line-height: 10px;
  //font-size: 16pt;
  //margin-top: 10px;
  //font-weight: bold;
  //color: #000;
  //font-size: 10px;
}

.mention-input-placeholder {
  width: 100%;
  padding: 10px 20px;
  background-color: #eff2f5;
  outline: 0;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  &:hover {
    background-color: #e4e6e8;
  }
  span {
    color: #64676b;
    font-size: 16px;
  }

}
