.file-drop {
  border: 1px dashed #000;
  padding: 25px;
  //cursor: pointer;
  //&:hover {
  //  background-color: #EBECF0;
  //}
  //height: 100px;
  &.dragging {
    background-color: #efefef;
  }
}
.attachment-title-row {
  border-bottom: 1px solid #c3c3c3;
}
