.react-flow,  {
  height: calc(100vh - 190px);
  //left: 0;
  //position: absolute;
  //top: 0;
  //width: 100%;
}

.checkbox-container {
  background-color: #fff;
  margin-top: 15px;
  padding: 15px 15px 5px 15px;
}
.workflow-footer {
  margin-left: -20px;
  margin-right: -15px;
  text-align: center;
  padding: 20px;
}


.edgebutton {
  //margin-left: -12px;
}
.edgebutton-foreignobject {
  //x: 285
}

.workflow-adder-top {
  background-color: rgb(153, 153, 153);
  height: 16px;
  left: 50%;
  margin-left: -1px;
  position: relative;
  width: 2px;
  z-index: 0;
}
.workflow-adder-bottom {
  background-color: rgb(153, 153, 153);
  height: 16px;
  left: 50%;
  margin-left: -1px;
  position: relative;
  width: 2px;
  z-index: 0;
}

.workflow-adder-arrow {
  color: rgb(153, 153, 153);
  margin-bottom: -1px;
  margin-top: -4px;
  padding-left: 1px;
  position: relative;
  z-index: 4;
  line-height: 1;
  text-align: center;
}
.btn-gap {
  background-color: rgb(153, 153, 153);
  height: 20px;
  left: 50%;
  margin-left: -1px;
  position: relative;
  width: 2px;
  z-index: 0;
}
.wkfl-add {
  //background-color: transparent!important;
  min-height:25px;
  padding: 0;
}
.workflow-scroll-container {
  padding-top: 20px;
  max-height: calc(100vh - 123px)!important;
  overflow-x: hidden;
  margin-right: -20px;
  margin-left: -20px;
}
.workflow-container {
  max-width:700px!important;

}
.workflow-adder-button-container {
  //background-color: rgb(153, 153, 153);
  left: 50%;
  margin-left: -14.5px;
  position: absolute;
  width: 2px;
  height: 5px;
  z-index: 0;
}
.workflow-drawer {
  padding: 10px;
}
.inline-edit-with-label {
  label {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.28581;
    text-transform: none;
    color: #182026;
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
    border-bottom: dotted 1px;
  }
}

.read-only-inline-edit {
 // line-height: 1.5;
  background-color: #fcfcfc;
  min-height: 25px;
  padding: 5px;
  word-break: break-word;
  font-weight: bold;
  border: 1px solid #d2d2d2;
  cursor: pointer;
  &.no-hover {
    cursor: default;
    border: none;
  }
  &:hover {
    background-color: #efefef;
    &.no-hover {
      background-color: #fcfcfc;

    }
  }
  &.sub {
    font-weight: normal;
  }
}

