.post-comment-box-wrapper {
  padding: 5px;
}

.post-inline-comment-avatar {
  display: inline-block;
}
.post-inline-comment-buttons {
  display: inline-block;
}

.post-inline-comment-field {
  border-color: transparent!important;
  display: inline-block!important;;
  width: calc(100% - 102px)!important;
}

.post-inline-comment-field-no-button {
  border-color: transparent!important;
  display: inline-block!important;;
  width: calc(100% - 40px)!important;
}

.post-inline-input-field {
  border-color: transparent!important;
  display: inline-block!important;;
  width: calc(100% - 130px)!important;
  &.complete {
    color: #575757;
  }
}
