.add-office-button {
  height: 125px;
  text-align: center;
  border: 1px dashed #000;
  &:hover {
    background-color: #ebecf0;
    cursor: pointer;
  }
}

.title-help-icon {
  display: inline;
  margin-top: -5px;
  span {
    cursor: pointer;
  }
}
