.task-dialog {
  width: 700px!important;
  .text-bold {
    font-weight: 600!important;
  }
  .bp3-tab {
    font-weight: 600!important;
  }
  //label {
  //  font-weight: bold;
  //}
  .bp3-dialog-body {
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 15px!important;
  }
  .right-panel {
    border-left: 1px solid #c3c3c3
  }
}

.pointer {
  cursor: pointer;
}

.rotate-90 {
  transform: rotate(90deg);
}

.inline-input-check {
  display: inline-block;
  margin-top: 9px;
  //width: 10%;
}
.ql-editor {
  min-height: 300px;
}
.inline-comment-avatar {
  display: inline-block;
}
.inline-comment-buttons {
  display: inline-block;
}
.comment-box-wrapper {
  padding: 5px;
}
.comment-drawer-wrapper {
  background-color: #ebecf0;
}
.selected-issue {
  background-color: #ebecf0;
  border-radius: 5px;
}
.inline-comment-field {
  border-color: transparent!important;
  display: inline-block!important;;
  width: calc(100% - 102px)!important;
}

.inline-comment-field-no-button {
  border-color: transparent!important;
  display: inline-block!important;;
  width: calc(100% - 40px)!important;
}

.inline-input-field {
  border-color: transparent!important;
  display: inline-block!important;;
  width: calc(100% - 130px)!important;
  &.complete {
    color: #575757;
  }
}
.checklist-dialog {
  width: 600px;
}
