@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);}
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}
.display-none {
  display: none;
}
.upload-overlay {
  position:absolute;
  top: 85px;
  bottom: 72px;
  left: 0;
  right: 1px;
  z-index: 9999;
  background-color: #f0f7ff!important;
  box-shadow: 0 0 0 1px #027ffe!important;
  .upload-icon {
    width: 50px;
    height: 50px;
   // background-color: red;
    /* Center horizontally*/
    position: absolute;
    bottom: 100px;
    left: 50%;
    margin: -25px 0 0 -25px;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
  .drop-alert {
    text-align: center;
    position:absolute;
    bottom: 20px;
    right: 1px;
    width: 400px;
    left: 50%;
    margin: -200px 0 0 -200px;
    .MuiAlert-icon {
      display: none;
    }
  }
}
.rc-tree-node-selected {
  background-color: #f0f7ff!important;
  box-shadow: 0 0 0 1px #027ffe!important;
  opacity: 0.8;
}

.rc-tree-title {
  padding-right: 10px;
  padding-left: 5px;
}
.left-pane {
  overflow-y: auto;
  height: calc(100vh - 122px);
  padding: 20px;
  background-color: #f5f8fa;
  position: relative;
  //background-color: #fff;
  //background-color: #fff;
  //margin-right: -5px;
}
.right-pane {
  overflow-y: auto;
  height: calc(100vh - 195px);
  padding: 10px 20px 20px 20px;
  background-color: #f5f8fa;
  //background-color: #fff;
  //margin-right: -5px;
}

.document-pane {
  overflow-y: auto;
  height: calc(100vh - 195px);
  padding: 10px 20px 20px 20px;
  background-color: #f5f8fa;
  //background-color: #fff;
  //margin-right: -5px;
}



.document-parent {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 50px);

}

.document-parent > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 10ch;
}

.document-sidebar {
  flex-basis: 15rem;
  flex-grow: 1;
  overflow-y: auto;

  //align-items: stretch;
  nav {
    background-color: #fff;
  }
}

.document-not-sidebar {
  flex-basis: 0;
  flex-grow: 100;
  padding: 0;
  background-color: red;
  overflow-y: hidden;
  //background-color: #f5f8fa;
  //background-color: #ebecf0;

}

.document-tree {
  overflow: auto;
}

.bottom-border-bar {
  border-bottom: 1px solid #e0e0e0;
}
