.post-actions {
  padding: 5px 5px 5px 5px;
  border: 1px solid silver;
  border-radius: 10px;
  margin-bottom: 15px;
  position: relative;
  .close-actions {
    position: absolute;
    z-index: 9999;
    top: 30px;
    right: 30px;
  }
}


.MuiAutocomplete-groupLabel {
  text-align: left!important;
}
.post-link-selector {
  width: 100%;
  height: 100%;
  border: 1px dashed #eff2f5;
  border-radius: 10px;
  background-color: #f7f8fa;
  cursor: pointer;
  position: relative;
  .link-selector-container {
    width: 400px;
    height: 200px;
    text-align: center;
    /* Center vertically and horizontally */
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -200px;
  }
  .document-selector-container {
    width: 430px;
    height: 200px;
    text-align: center;
    /* Center vertically and horizontally */
    position: absolute;
    top: 65%;
    left: 50%;
    margin: -50px 0 0 -215px;
  }

}
.post-file-uploader {
  width: 100%;
  height: 100%;
  border: 1px dashed #eff2f5;
  border-radius: 10px;
  background-color: #f7f8fa;
  cursor: pointer;
  position: relative;
  &.dragging {
    background-color: #eff2f5;
  }
  &:hover {
    background-color: #eff2f5;
  }
  .uploader-info {
    width: 200px;
    height: 200px;
    text-align: center;
    /* Center vertically and horizontally */
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -100px;
    .upload-title {
      margin-top: 10px;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
}

.file-item {
  padding: 3px;
  position: relative;
  border-radius: 10px!important;
  .file-item-delete {
    position: absolute;
    top: 7px;
    right: 5px;
    opacity: 0;
  }
  &:hover {
    .file-item-delete {
      opacity: 1;
    }
  }
}

.link-item {
  padding: 3px;
  position: relative;
  border-radius: 10px!important;
  .link-item-delete {
    position: absolute;
    top: 3px;
    right: 5px;
    opacity: 0;
  }
  &:hover {
    .link-item-delete {
      opacity: 1;
    }
  }
}

.fade-visible {
  display: flex!important;
  transition: display 2s linear;
}
.fade-hidden {
  display: none!important;
  transition: display 3s 2s
}
